<template>
  <section>
    <div class="main-contents-wrapper">
      <div class="table-wrapper">
        <table class="mainTable">
          <tr>
            <th rowspan="2">추천 코드</th>
            <th rowspan="2">상위 유저</th>
            <th rowspan="2">보너스 금액</th>
            <th rowspan="2">롤링 조건 %</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4"></td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
        <table class="mainTable">
          <tr>
            <th rowspan="2">추천 코드</th>
            <th rowspan="2">상위 유저</th>
            <th rowspan="2">보너스 금액</th>
            <th rowspan="2">롤링 조건 %</th>
            <th rowspan="2">스포츠 %</th>
            <th rowspan="2">미니게임 %</th>
            <th rowspan="2">E-스포츠 %</th>
            <th rowspan="2">키론 %</th>
            <th colspan="18">카지노</th>
            <th rowspan="2">상태</th>
            <th rowspan="2">동작</th>
          </tr>
          <tr>
            <th colspan="3">에볼루션 %</th>
            <th colspan="3">빅게이밍 %</th>
            <th colspan="3">섹시게이밍 %</th>
            <th colspan="3">아시안게이밍 %</th>
            <th colspan="2">드림게이밍 %</th>
            <th colspan="2">PP 카지노 %</th>
            <th colspan="2">호텔카지노 %</th>
          </tr>
          <tr>
            <td rowspan="4"></td>
            <td rowspan="4"></td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td rowspan="4">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="3">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td colspan="2">0</td>
            <td rowspan="4"><input type="checkbox" class="set-switch"></td>
            <td rowspan="4">
              <div class="status-change-btn-wrap">
                <button class="btn-innerTable btn-status-change wait" type="button">{{ $t('button.modify') }}</button>
                <button class="btn-innerTable btn-status-change refuse" type="button">{{ $t('button.del') }}</button>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="18">슬롯</th>
          </tr>
          <tr>
            <th>슬롯-스페이드 %</th>
            <th>슬롯-PP %</th>
            <th>슬롯-하바네로 %</th>
            <th>슬롯-월드메치 %</th>
            <th>슬롯-넷앤트 %</th>
            <th>슬롯-메버릭 %</th>
            <th>슬롯-퀸스핀 %</th>
            <th>슬롯-MG %</th>
            <th>슬롯-플레이엔 고 %</th>
            <th>슬롯-YL게이밍 %</th>

            <th>슬롯-레드타이거 %</th>
            <th>YGG 드라실 %</th>
            <th>슬롯-드라군 소프트 %</th>
            <th>분고 %</th>
            <th>플레이슨 %</th>
            <th>CQ9 %</th>
            <th>플레이텍 %</th>
            <th>스카이윈드 %</th>
          </tr>
          <tr>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
        </table>
      </div>
    </div>
    <pagination v-if="tableData.length !== 0" @goPage="setTableData" :pageNum="pageInfo.page" :pageSize="pageInfo.count_per_list" :totalCount="pageInfo.tatal_list_count" />
  </section>
</template>

<script>
import TableHead from "@/components/main/table/Head.vue";
import BtnVisibleToggle from "@/components/ui/BtnVisibleToggle";
import SearchFilter from "@/components/common/SearchFilter";
import DateSelector from "@/components/common/DateSelector";
import Pagination from "@/components/common/Pagination";
import typing from "@/components/ui/Typing";
import UiSelect from "@/components/ui/Select";
import UiCheckSet from "@/components/ui/UiCheckSet";
import UiCheck from "@/components/ui/UiCheck";
export default {
  name: "EventFixCashIn",
  components: {
    UiSelect,
    UiCheckSet,
    UiCheck,
    BtnVisibleToggle,
    TableHead,
    SearchFilter,
    Pagination,
    DateSelector,
    typing
  },
  data() {
    return {
      tableName: this.$route.name,
      headInfo: {
        fstColumn: false,
        dataList: [
          "memNick",
          "qTitle2",
          "bannerimg",
          "writedate",
          "viewcount",
          "first",
          "empty"
        ]
      },
      reqData: {
        page: 1,
        count_per_list: 30,
        count_per_page: 30,
        memId: "",
        startDate: "",
        endDate: "",
        ip:""
      },
      srchFiltersProp: {
        selectOptions: []
      },
      dateProps:{
        start:true,
        end:false
      },
      tableData: [],
      btnText: "보기",
      memList: [],
      pageInfo: {},
      totalInfo: {},
      wlContentsActive: false
    };
  },
  methods: {
    wlContentsToggle() {
      const reverseArr = this.additionalHead.reverse();
      const addPosition = this.headInfo.dataList.indexOf("wl") + 1;
      this.wlContentsActive = !this.wlContentsActive;
      for (const item of reverseArr) {
        if (this.wlContentsActive) {
          this.headInfo.dataList.splice(addPosition, 0, item);
        } else {
          this.headInfo.dataList.splice(addPosition, 1);
        }
      }
    },
    openDetail_company() {
      // console.log(id)
    },
    // async setTableData(pageNum) {
    //     if (pageNum) {
    //     this.reqData.page = pageNum;
    //   }
    //   const data = this.reqData;
    //   const listRes = await userTracking(data);
    //   const pageInfo = listRes.data.pageInfo;
    //   this.pageInfo = pageInfo;
    //   const totalInfo = listRes.data.totalInfo;
    //   this.totalInfo = totalInfo;
    //   const memList = listRes.data.list;
    //   memList.forEach(items => {
    //     for (const item in items) {
    //       if (item === "actionDate" || item === "lastLoginDt") {
    //         items[item] = replaceDateT(items[item]);
    //       }
    //       if (!isNaN(Number(items[item]))) {
    //         items[item] = numberWithCommas(items[item]);
    //       }
    //     }
    //   });
    //   this.memList = memList;
    // }
  },
  async created() {
    // this.setTableData(1);
  }
};
</script>

<style scoped>
.mainTable {margin-bottom: 30px;}
.pt50 {padding-top: 50px;}
</style>
